import React, { useState, useEffect, Fragment, useRef } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ListGroup,
  Form,
  Input,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import {
  SEARCH_BY,
  SORT_BY,
  ADD_TO_CART,
  ADD_TO_WISHLIST,
} from "../../../redux/actionTypes";
import { Grid, List } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import errorImg from "../../../assets/images/search-not-found.png";
import Allfilters from "../../application/ecommerce-app/filters/allfilters";
import {
  filterBrand,
  filterColor,
  filterPrice,
  filterSearch,
} from "../../../redux/ecommerce/filter/action";

import { getVisibleproducts } from "./product.service";
import { watchfetchProducts } from "../../../redux/ecommerce/product/action";
import {
  Filters,
  ShowingProducts,
  Featured,
  LowestPrices,
  HighestPrices,
  NotFoundData,
  ProductDetails,
  Quantity,
  AddToCart,
  ViewDetails,
  ProductSizeArray,
  Ideas,
} from "../../../constant";
import { classes } from "../../../data/layouts";
import { useTranslation } from "react-i18next";
import Productpage from "../../application/ecommerce-app/productpage";

import axios from "axios";
import { saveAs } from "file-saver";

const SeeProductContainer = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const data = useSelector((content) => content.data.productItems);
  const layoutColumns = 3;
  // Get the information of the logged user
  const infoUserLogin = JSON.parse(localStorage.getItem("infoUserLogin"));
  const symbol = useSelector((content) => content.data.symbol);
  const [open, setOpen] = useState(false);
  const [sidebaron, setSidebaron] = useState(true);
  const [singleProduct, setSingleProduct] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filterSidebar, setFilterSidebar] = useState(true);
  const filters = useSelector((content) => content.filters);
  const products = getVisibleproducts(data, filters);
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const [permissionsModule, setPermissionsModule] = useState([]);

  useEffect(() => {
    dispatch(watchfetchProducts());
  }, [dispatch]);

  useEffect(() => {
    // Get general permissions

    if (infoUserLogin !== null) {
      if (infoUserLogin.id !== null && infoUserLogin.id !== "") {
        axios
          .get(
            `${process.env.REACT_APP_DOMAIN_SERVER}/api/getPermission/${infoUserLogin.idRol}`
          )
          .then((result) => {
            setPermissionsModule(result.data.response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setPermissionsModule([]);
      }
    } else {
      setPermissionsModule([]);
    }
  }, []);

  const filterSortFunc = (event) => {
    dispatch({ type: SORT_BY, sort_by: event });
  };

  const gridLayout = () => {
    document
      .querySelector(".product-wrapper-grid")
      .classList.remove("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-xl-3");
      el.classList.add("col-sm-6");
      el.classList.add("xl-4");
    });
  };

  const listLayout = () => {
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-xl-12");
    });
  };

  const LayoutView = (layoutColumns) => {
    if (
      !document
        .querySelector(".product-wrapper-grid")
        .classList.contains("list-view")
    ) {
      var elems = document.querySelector(".gridRow").childNodes;
      [].forEach.call(elems, function (el) {
        el.className = "";
        el.classList.add("col-xl-" + layoutColumns);
      });
    }
  };

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false);
      document.querySelector(".product-wrapper").classList.add("sidebaron");
    } else {
      setSidebaron(true);
      document.querySelector(".product-wrapper").classList.remove("sidebaron");
    }
  };

  const onOpenModal = (productId) => {
    setOpen(true);
    data.forEach((product, i) => {
      if (product.id === productId) {
        setSingleProduct(product);
      }
    });
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleSearchKeyword = (keyword) => {
    keyword = keyword.toLowerCase(keyword);
    setSearchKeyword(keyword);
    dispatch(filterSearch(keyword));
  };

  const onClickDetailPage = (id) => {
    // const id = product.id;
    navigate(
      `${process.env.PUBLIC_URL}/home/components/pages/CustomerFormHome/${id}`
    );
  };

  const onClickSeeDetailProduct = (id) => {
    // const id = product.id;
    navigate(
      `${process.env.PUBLIC_URL}/home/components/pages/CustomerSeeDetailProduct/${id}`
    );
  };

  const onClickManagementPage = (id) => {
    // const id = product.id;
    navigate(
      `${process.env.PUBLIC_URL}/app/inventory/product/ManageProduct/${id}/${layout}`
    );
  };

  const onClickDownloadImages = (id) => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/downloadAllImages/${id}`)
      .then((result) => {
        const downloadLinks = result.data.data.map((v) =>
          v.link.replace("http:", "https:")
        );
  
        downloadLinks.forEach((link) => {
          download(link);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  // Function that allows getting the file name
  function getFileName(url) {
    const posicionUltimaBarra = url.lastIndexOf("/");
    return url.substring(posicionUltimaBarra + 1);
  }
  
  async function download(url) {
    try {
      // Create an <a> element with href
      const a = document.createElement("a");
      a.href = await toDataURL(url);
      console.log("jere   "+ getFileName(url));
      a.download = getFileName(url); // Ensure this function is defined
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download failed:", error);
    }
  }
  

  function toDataURL(url) {
    return axios(url, {
      method: "GET",
      responseType: "blob",
    })
      .then((result) => {
        return result.data;
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }
  

  return (
    <Container fluid={true} className="product-wrapper">
      <div className="product-grid">
        <div className="feature-products">
          <Row>
            <Col md="3" sm="12" className="products-total">
              <div className="square-product-setting d-inline-block">
                <a
                  className="icon-grid grid-layout-view"
                  onClick={gridLayout}
                  href="#javascript"
                >
                  <Grid />
                </a>
              </div>
              <div className="square-product-setting d-inline-block">
                <a
                  className="icon-grid m-0 list-layout-view"
                  onClick={listLayout}
                  href="#javascript"
                >
                  <List />
                </a>
              </div>
              <span
                className="d-none-productlist filter-toggle"
                onClick={() => setFilterSidebar(!filterSidebar)}
              >
                <h6 className="mb-0">
                  {Filters}
                  <span className="ms-2">
                    <i className="toggle-data fa fa-chevron-down"></i>
                  </span>
                </h6>
              </span>
              <div className="grid-options d-inline-block">
                <ListGroup as="ul">
                  <li>
                    <a
                      className="product-2-layout-view"
                      onClick={() => LayoutView(6)}
                      href="#javascript"
                    >
                      <span className="line-grid line-grid-1 bg-primary"></span>
                      <span className="line-grid line-grid-2 bg-primary"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="product-3-layout-view"
                      onClick={() => LayoutView(4)}
                      href="#javascript"
                    >
                      <span className="line-grid line-grid-3 bg-primary"></span>
                      <span className="line-grid line-grid-4 bg-primary"></span>
                      <span className="line-grid line-grid-5 bg-primary"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="product-4-layout-view"
                      onClick={() => LayoutView(3)}
                      href="#javascript"
                    >
                      <span className="line-grid line-grid-6 bg-primary"></span>
                      <span className="line-grid line-grid-7 bg-primary"></span>
                      <span className="line-grid line-grid-8 bg-primary"></span>
                      <span className="line-grid line-grid-9 bg-primary"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="product-6-layout-view"
                      onClick={() => LayoutView(2)}
                      href="#javascript"
                    >
                      <span className="line-grid line-grid-10 bg-primary"></span>
                      <span className="line-grid line-grid-11 bg-primary"></span>
                      <span className="line-grid line-grid-12 bg-primary"></span>
                      <span className="line-grid line-grid-13 bg-primary"></span>
                      <span className="line-grid line-grid-14 bg-primary"></span>
                      <span className="line-grid line-grid-15 bg-primary"></span>
                    </a>
                  </li>
                </ListGroup>
              </div>
            </Col>
            <Col md="9" sm="12" className="text-end">
              <span className="f-w-600 m-r-5">{ShowingProducts}</span>
              <div
                className="select2-drpdwn-product select-options d-inline-block"
                onChange={(e) => filterSortFunc(e.target.value)}
              >
                <select className="form-control btn-square" name="select">
                  <option value="Featured">{Featured}</option>
                  <option value="LowestPrices">{LowestPrices}</option>
                  <option value="HighestPrices">{HighestPrices}</option>
                </select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="3" md="6">
              <div className={`product-sidebar ${filterSidebar ? "" : "open"}`}>
                <div className="filter-section">
                  <Card>
                    <CardHeader>
                      <h6 className="mb-0 f-w-600">
                        {Filters}
                        <span className="pull-right">
                          <i
                            className="fa fa-chevron-down toggle-data"
                            onClick={onClickFilter}
                          ></i>
                        </span>
                      </h6>
                    </CardHeader>
                    <div className="left-filter">
                      <CardBody className="filter-cards-view animate-chk">
                        <Allfilters />
                      </CardBody>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
            <Col xl="9" md="6">
              <Form>
                <div className="m-0 search-product">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="search"
                    defaultValue={searchKeyword}
                    onChange={(e) => handleSearchKeyword(e.target.value)}
                  />
                  <i className="fa fa-search"></i>
                </div>
              </Form>
            </Col>
          </Row>
        </div>

        <div className="product-wrapper-grid">
          {searchKeyword.length > 0 && products.length <= 0 ? (
            <div className="search-not-found text-center">
              <div>
                <img
                  className="img-fluid second-search"
                  src={errorImg}
                  alt=""
                />
                <p>{NotFoundData}</p>
              </div>
            </div>
          ) : (
            <Row className="gridRow">
              {products
                ? products.map((item, i) => (
                    <div
                      className={`${
                        layoutColumns === 3
                          ? "col-xl-3 col-sm-6 xl-4 col-grid-box"
                          : "col-xl-" + layoutColumns
                      }`}
                      key={i}
                    >
                      <Card>
                        <div className="product-box">
                          <div className="product-img">
                            <img className="img-fluid" src={item.img} alt="" />

                            <div className="product-hover">
                              <ul>
                                <li>
                                  <Button
                                    color="default"
                                    data-toggle="modal"
                                    onClick={() => onOpenModal(item.id)}
                                  >
                                    {/* onClick={() => onClickDetailPage(item.id)}> */}

                                    <i className="icon-eye"></i>
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-details">
                            <div className="rating">
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </div>
                            <h4 className="font-primary">{item.name}</h4>
                            <p>{item.note}</p>
                            <div className="product-price">
                              {symbol} {item.price}
                              {/* <del>{symbol} {item.discountPrice}</del> */}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))
                : ""}

              <Modal
                size="lg"
                className="modal-dialog-centered product-modal modalLine"
                isOpen={open}
              >
                <ModalHeader toggle={onCloseModal} className="titleModalP">
                  <strong>{singleProduct.name}</strong>
                </ModalHeader>
                <ModalBody>
                  <div className="product-box row" align="center">
                    <Col lg="7" className="product-img">
                      {/* <Media className="img-fluid" src={singleProduct.img ? singleProduct.img : ""} alt="" /> */}
                      <Productpage data={singleProduct} />
                    </Col>
                    <Col lg="5" className="product-details  text-start">
                      <h4 className="descriptionInfoPopup">
                        {singleProduct.name}
                      </h4>
                      <div className="product-price descriptionInfoPopup">
                        {symbol}
                        {singleProduct.price}
                        {/* <del>{symbol}{singleProduct.discountPrice}</del> */}
                      </div>
                      <div className="product-view">
                        <h6 className="f-w-600 descriptionInfoPopup">
                          {t("productDetail")}
                        </h6>
                        <p className="descriptionInfoPopup">
                          <strong>{t("nameBrand")} :</strong>
                          {singleProduct.tags}
                        </p>
                        <p className="mb-0 descriptionInfoPopup">
                          {singleProduct.description}
                        </p>
                      </div>
                      <div className="product-qnty">
                        <div className="addcart-btn">
                          {/* Set up alert */}
                          {/* <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/cart/${layout}`}><Button color="primary" className="me-2 mt-2" >{t('buy')}</Button></Link> */}
                          <Button
                            onClick={() => onClickDetailPage(singleProduct.id)}
                            color="primary"
                            className="me-2 mt-2"
                          >
                            {t("buy")}
                          </Button>
                          <Button
                            onClick={() =>
                              onClickSeeDetailProduct(singleProduct.id)
                            }
                            color="primary"
                            className="me-2 mt-2"
                          >
                            {t("see")}
                          </Button>
                          {permissionsModule.find((v) => {
                            return (
                              v.functionName === "vender_reservar_producto"
                            );
                          }) ? (
                            <Button
                              onClick={() =>
                                onClickManagementPage(singleProduct.id)
                              }
                              color="primary"
                              className="me-2 mt-2"
                            >
                              {t("btnMangement")}
                            </Button>
                          ) : (
                            ""
                          )}

                          <Button
                            onClick={() =>
                              onClickDownloadImages(singleProduct.id)
                            }
                            color="primary"
                            className="me-2 mt-2"
                          >
                            {t("btnDownload")}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </div>
                </ModalBody>
              </Modal>
            </Row>
          )}
        </div>
      </div>
    </Container>
  );
};

export default SeeProductContainer;
