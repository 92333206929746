import React, { useState, Fragment, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Input,
  Card,
  FormGroup,
  InputGroup,
  InputGroupText,
  CardHeader,
  Table,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import { SelectBox } from "devextreme-react/select-box";
import TimePickerOne from "../forms/form-widget/timepickerComponent/timepicker-one";
import DatePicker from "react-datepicker";
import { Hidden } from "@material-ui/core";

export default function SellerForm({
  register,
  errors,
  setComment,
  singleProductImg,
  listStatus,
  statusRequest,
  setStatusRequest,
  validateClass,
  deliveryDate,
  setDeliveryDate,
}) {
  const endDate = new Date();

  const handleChangeDeliveryDate = (date) => {
    setDeliveryDate(date);
  };

  // User translation
  const { t } = useTranslation();

  const handleStatusProduct = (newvalue) => {

    if (newvalue.value !== null) {
      // Set the id
      if (newvalue.value !== undefined) {
        setStatusRequest(newvalue.value);
      }
    } else {
      // Clear the information of Status brand
      setStatusRequest("");
    }
  };

  return (
    <Row>
      <Col md="12 mb-1">
        <Label>{t("productName")}</Label>
        <input
          className="form-control btn-pill"
          type="text"
          readOnly
          defaultValue={singleProductImg.name}
          placeholder={t("productName")}
          {...register("productName", { required: false })}
        />
      </Col>
      <Col md="6 mb-2" className="hiddeBloq">
        <Label>{t("selectStatus")}</Label>
        {listStatus.length > 0 ? (
          <SelectBox
            dataSource={listStatus}
            displayExpr="name"
            valueExpr="id"
            defaultValue={listStatus.length > 0 ? listStatus[1].id : 0}
            searchEnabled={true}
            className={"form-control dxSelectBorder"}
            placeholder={t("selectStatus")}
            showClearButton={true}
            name="selectStatus"
            onValueChanged={handleStatusProduct}
          />
        ) : (
          ""
        )}

        <input type="hidden" />
        <span>
          {(statusRequest === "" || statusRequest === undefined) &&
            validateClass &&
            t("errorStatus")}
        </span>
        <div className="valid-feedback">{"Looks good!"}</div>
      </Col>
      <Col md="6 mb-2">
        <Label>{t("firstName")}</Label>
        <input
          className="form-control btn-pill"
          type="text"
          placeholder={t("placeHolderFirstName")}
          {...register("firstName", { required: true })}
        />
        <span>{errors.firstName && t("errorRequired")}</span>
      </Col>
      <Col md="6 mb-2">
        <Label>{t("phoneNumber")}</Label>
        <input
          className="form-control btn-pill"
          type="text"
          placeholder={t("placeHolderPhoneNumber")}
          {...register("phoneNumber", { required: true })}
        />
        <span>{errors.phoneNumber && t("errorRequired")}</span>
      </Col>
      

      
      
      <Col md="6 mb-2">
        <Label>{t("price")} $</Label>
        {singleProductImg.price !== undefined ? (
          <input
            className="form-control btn-pill"
            type="number"
            placeholder={t("price")}
            {...register("price", {
              value: singleProductImg.price,
              required: false,
              min: 100,
            })}
          />
        ) : (
          ""
        )}

        <span>{errors.price && t("errorPrice")}</span>
      </Col>
      <Col md="6 mb-2">
        <Label>{t("deliveryService")} $</Label>
        <input
          className="form-control btn-pill"
          type="number"
          placeholder={t("deliveryService")}
          {...register("deliveryService", { required: false, value: 0 })}
        />
      </Col>

      <Col md="6 mb-2">
        <div className="hiddeBloq">
          <Label>{t("lastName")}</Label>
          <input
            className="form-control btn-pill"
            type="text"
            placeholder={t("placeHolderLastName")}
            {...register("lastName", { required: false })}
          />
          <span>{errors.lastName && t("errorRequired")}</span>
        </div>
      </Col>

      <Col md="6 mb-2" className="hiddeBloq">
        <Label>{t("email")}</Label>
        <input
          className="form-control btn-pill"
          type="text"
          placeholder={t("placeholderEmail")}
          {...register("email", {
            required: false,
            pattern:
              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
          })}
        />
        <span>{errors.email && t("errorEmail")}</span>
      </Col>
      <Col md="6 mb-2" className="hiddeBloq">
        <Label>{t("linkMeta")}</Label>
        <input
          className="form-control btn-pill"
          type="text"
          placeholder={t("placeHolderLinkMeta")}
          {...register("linkMeta", { required: false })}
        />
        {/* <span>{errors.linkMeta && t("errorRequired")}</span> */}
      </Col>
      <Col md="6 mb-2" className="hiddeBloq">
        <Label>{t("deliveryDate")}</Label>
        <DatePicker
          className="form-control digits"
          selected={deliveryDate}
          onChange={handleChangeDeliveryDate}
          dateFormat="dd/MM/yyyy"
        />
      </Col>
      <Col md="12 mb-1">
        <Label>{t("address")}</Label>
        <input
          className="form-control btn-pill"
          type="text"
          placeholder={t("placeHolderAddress")}
          {...register("address", { required: false })}
        />
      </Col>

      <Col md="12 mb-1">
        <Label>{t("notes")}</Label>
        <Input
          type="textarea"
          className="form-control btn-pill"
          rows="2"
          name="comment"
          placeholder={t("notes")}
          onChange={(e) => setComment(e.target.value)}
        />
      </Col>
    </Row>
  );
}
