import React, { Fragment, useState, useEffect, useRef } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import axios from "axios";
import { classes } from "../../../data/layouts";
import 'devextreme/dist/css/dx.material.teal.light.css';
// import PopupBrand from "./popupBrand";
import { useParams } from "react-router-dom";
import { SelectBox } from 'devextreme-react/select-box';
import { SiWhatsapp, SiFacebook, SiGooglemaps } from "react-icons/si";
import Productpage from "../../application/ecommerce-app/productpage";
import SellerForm from "../../customers/sellerForm";


import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Lookup,
  SearchPanel,
  Scrolling,
  Pager,
  Export,
  HeaderFilter,
  RequiredRule
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// import Moment from 'react-moment';
import moment from 'moment';
import { useNavigate } from "react-router-dom";


export default function ManageProduct() {

  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');

  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  // Get the information of the logged user
  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  // To traslate the words
  const { t } = useTranslation();

  const navigate = useNavigate();

  // Spaces
  const tab = '\u00A0';

  // Class allow validate
  const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

  const [validateClass, setValidateClass] = useState(false);

  //for errors setup
  const [error, setError] = useState({});

  // Loading
  const [loading, setLoading] = useState(false);

  const [deliveryDate, setDeliveryDate] = useState(new Date())

  // Charge delivery
  const [chargeDelivery, setChargeDelivery] = useState(25);
  const [price, setPrice] = useState(0);
  const [brand, setBrand] = useState('');
  // To get the comment of Customer
  const [comment, setComment] = useState('');

  // Note request
  const [noteRequest, setNoteRequest] = useState([]);

  // Total payment
  const [productName, setProductName] = useState(0);


  // To get the list of status
  const [listStatus, setListStatus] = useState([]);

  // To get the status 
  const [statusRequest, setStatusRequest] = useState([]);

  const [singleProductImg, setSingleProductImg] = useState({});
  // Take id
  let { id } = useParams();

  // Save language
  const [languageClient, setLanguageClient] = useState('en');

  //Get the language
  const browserLanguaje = navigator.language || navigator.userLanguaje;


  const onSubmit: SubmitHandler<FormValues> = data => {
    // If all validations are met we'll call register method
    if (statusRequest !== '' && statusRequest !== null && statusRequest !== undefined) {
      createRequest(data);
    }
  }

  // Get the list of status only load once time

  useEffect(() => {
    // We pass like parameter 1 because 1 has the general status
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/processState/${4}`)
      .then((response) => {
        // Filter only status pending and finalized
        setListStatus(response.data.listStatus.filter(v => { return v.id == 8 || v.id == 10 }));

        // Set up pending as default value 8; status 10 it means the request ended it up
        setStatusRequest(10);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  // Function that allow save a new record
  const createRequest = (data) => {
    //validation empy fields
    if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
    setLoading(true);

      if (deliveryDate !== null) {
        let deliveryD = moment(deliveryDate).format('YYYY-MM-DD');

        const infoCreate = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          linkMeta: data.linkMeta,
          deliveryDate: deliveryD,
          price: data.price,
          chargeDelivery: data.deliveryService,
          address: data.address,
          comment: comment,
          idProduct: id,
          totalPurchase: parseFloat(data.deliveryService) + parseFloat(data.price),
          whoCreated:infoUserLogin.id,
          languageClient: "es",
          status:statusRequest
        };


        axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/createRequestRefrigeratorBySeller`, infoCreate)
          .then((response) => {
            setValidateClass(false);
            setLoading(false);
            toast.info(t('successCreated'));
            // Load the list of brands
            emptyFields();
            navigate(`${process.env.PUBLIC_URL}/app/sales/requests/MyRequestList/${layout}`);

          })
          .catch((errors) => {
            setError(errors.response.data.messages)
            console.log(errors);
          });
      } else {
        toast.error(t('errorDate'));
      }

    } else {
      setTimeout(() => {
        toast.error(t('errorLogin'));
      }, 200);
    }

  };

  function emptyFields() {
    setValidateClass(false);
    reset();
    setNoteRequest([]);
    setComment('');
  }

  useEffect(() => {

    if (browserLanguaje.includes('es')) {
      setLanguageClient('es');
    } else {
      setLanguageClient('us');
    }
    loadProductsById(id);
  }, [id]);


  function loadProductsById(idProduct) {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInfoProductoById/${idProduct}`)
      .then((response) => {
        setPrice(response.data[0].price);
        setBrand(response.data[0].tags[0]);
        setSingleProductImg(response.data[0]);
        setProductName(response.data[0].name);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Fragment>
      <Breadcrumb title={t("btnMangement")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12" xl="10">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12" lg="6" xl="6" className="col-12">

                    <div className="product-box row" align="center" >
                      <Productpage data={singleProductImg} />
                    </div>
                  </Col>
                  <Col sm="12" lg="6" xl="6">

                    <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                      <Col className="col-12" style={{ marginTop: '10px' }}>
                        <h6 className="sub-title formTitleRegisterCustomer">{t("generalInformation")}</h6>
                      </Col>
                      <Row>
                        <SellerForm
                          register={register}
                          errors={errors}
                          setComment={setComment}
                          singleProductImg={singleProductImg}
                          statusRequest={statusRequest}
                          listStatus={listStatus}
                          setStatusRequest={setStatusRequest}
                          validateClass={validateClass}
                          deliveryDate={deliveryDate}
                          setDeliveryDate={setDeliveryDate}
                        />
                      </Row>

                      <ModalFooter>
                        <Button color="secondary" onClick={emptyFields} >{t('cancel')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : t('request')}</Button>
                      </ModalFooter>
                    </Form>

                    <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                      <span></span></div>
                    </div>
                    <br /><br />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};



// export default ManageProduct;
